<template>
  <div id="app">
    <b-container>
      <!-- <h2 class="mb-5">Company search</h2> -->

      <b-jumbotron
        bg-variant="dark"
        text-variant="white"
        border-variant="dark"
        header="Company finder"
        lead="Find the location of a company inside Technopark."
      >
        <!-- <p>For more information visit website</p>
        <b-button variant="primary" href="#">More Info</b-button> -->
      </b-jumbotron>

      <div style="height: 530px">
        <!-- <b-input-group prepend="Company" class="mt-3"> -->
        <b-input-group
          class="mt-3"
          v-if="selectedCompanyIndex < 0 && filteredCompanies.length > 1"
        >
          <b-form-input
            v-model="keyboardInput"
            placeholder="Use the keyboard to search a company"
            size="lg"
          ></b-form-input>
        </b-input-group>

        <!-- <HelloWorld msg="Welcome to Your Vue.js App" /> -->
        <b-list-group class="mt-1">
          <b-list-group-item
            v-for="company in filteredCompanies"
            v-bind:key="company.name"
            class="flex-column align-items-start"
            v-on:click="selectCompany(company)"
          >
            <!-- src="company.mapFilePath" -->
            <!-- src="./assets/maps/Darwin0.png" -->
            <!-- :src="require('./assets/maps/Darwin0.png')" -->
            <h5 class="mb-1">{{ company.name }}</h5>
            <small>{{ company.originalString }}</small>
            <b-img
              v-if="selectedCompanyIndex >= 0 || filteredCompanies.length == 1"
              class="mt-3 mb-3"
              center
              fluid
              :src="company.mapFilePath"
              alt="Center image"
            ></b-img>
          </b-list-group-item>
        </b-list-group>
      </div>

      <!-- v-if="selectedCompanyIndex >= 0 || filteredCompanies.length == 1" -->
      <b-button
        active
        variant="success"
        v-on:click="onSearchAnotherCompanyClicked"
        class="mt-3 mb-3 w-100"
        >Search another company</b-button
      >
      <!-- <b-button
        v-if="selectedCompanyIndex < 0 && filteredCompanies.length > 1"
        variant="success"
        v-on:click="onClearSearchClicked"
        class="mt-3 mb-3 w-100"
        >Clear search</b-button
      > -->

      <SimpleKeyboard
        v-if="selectedCompanyIndex < 0 && filteredCompanies.length > 1"
        @onChange="onKeyboardChange"
        @onKeyPress="onKeyboardKeyPress"
        :input="keyboardInput"
      />
    </b-container>
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import SimpleKeyboard from "./components/SimpleKeyboard.vue";
import companyData from "./assets/companies.json";
import fz from "fuzzaldrin-plus";

function mapFilePathForCompany(company) {
  let mapFilePath = require("./assets/maps/None.png");
  if (
    company.office_floor >= 0 &&
    company.office_floor < 7 &&
    company.office_area != "unknown"
  ) {
    // Sometimes office_floor is "Unknown"
    mapFilePath = require("./assets/maps/" +
      company.office_area +
      company.office_floor +
      ".png");
  }

  return mapFilePath;
}

export default {
  name: "App",
  components: {
    // HelloWorld
    SimpleKeyboard,
  },

  // Note: The data does not need to be reactive and could be defined as a static option
  // https://stackoverflow.com/questions/45565349/how-to-acces-external-json-file-objects-in-vue-js-app
  // companies: [],

  returnToMainViewTimer: null,
  data: () => ({
    keyboardInput: "",
    companies: companyData.companies.map((company, index) => {
      company.companyIndex = index;
      company.mapFilePath = mapFilePathForCompany(company);
      return company;
    }),
    selectedCompanyIndex: -1,
  }),

  computed: {
    filteredCompanies() {
      let filteredCompanies = this.companies;

      if (this.selectedCompanyIndex >= 0) {
        filteredCompanies = filteredCompanies.filter(
          (company) => company.companyIndex == this.selectedCompanyIndex
        );
      } else {
        if (this.keyboardInput == "") {
          filteredCompanies = filteredCompanies.sort((companyA, companyB) => {
            if (companyA.name < companyB.name) {
              return -1;
            }
            if (companyA.name > companyB.name) {
              return 1;
            }
            return 0;
          });
        } else {
          const preparedQuery = fz.prepareQuery(this.keyboardInput.toLowerCase());
          filteredCompanies = filteredCompanies.sort((companyA, companyB) => {
            return (
              fz.score(companyB.name, this.keyboardInput.toLowerCase(), {
                preparedQuery,
              }) -
              fz.score(companyA.name, this.keyboardInput.toLowerCase(), {
                preparedQuery,
              })
            );
          });

          if (
            fz.score(filteredCompanies[0].name, this.keyboardInput.toLowerCase(), {
              preparedQuery,
            }) >
            2 *
              fz.score(filteredCompanies[1].name, this.keyboardInput.toLowerCase(), {
                preparedQuery,
              })
          ) {
            filteredCompanies = filteredCompanies.slice(0, 1);
          }
        }
      }

      return filteredCompanies.slice(0, 6);
    },
  },

  methods: {
    onKeyboardChange(keyboardInput) {
      this.keyboardInput = keyboardInput;
    },
    onKeyboardKeyPress(button) {
      this.refreshTimer();
      console.log("button", button);
    },
    onClearSearchClicked() {
      this.keyboardInput = "";
      this.clearTimer();
    },
    clearTimer() {
      clearTimeout(this.$options.returnToMainViewTimer);
      this.$options.returnToMainViewTimer = null;
    },
    onSearchAnotherCompanyClicked() {
      this.keyboardInput = "";
      this.selectedCompanyIndex = -1;
      this.clearTimer();
    },
    refreshTimer() {
      if (this.$options.returnToMainViewTimer != null) {
        clearTimeout(this.$options.returnToMainViewTimer);
      }
      this.$options.returnToMainViewTimer = setTimeout(
        function (thisCopy) {
          thisCopy.keyboardInput = "";
          thisCopy.selectedCompanyIndex = -1;
          clearTimeout(thisCopy.$options.returnToMainViewTimer);
          thisCopy.$options.returnToMainViewTimer = null;
        },
        2 * 60 * 1000,
        this
      );
    },
    selectCompany(company) {
      this.refreshTimer();
      this.selectedCompanyIndex = company.companyIndex;
    },
  },
  created: function () {
    // Refresh every 12 hours
    setTimeout("location.reload(true);", 12 * 1000 * 60 * 60); // Milliseconds
  },
};
</script>

<style>
body,
html,
#app {
  padding: 0;
  margin: 0;
  width: 1080px;
  height: 1920px;
  /* width: 100%;
  height: 100%; */
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding-top: 200px;

  background-image: url("assets/Background-Blurred.jpg");
  /* background: linear-gradient(
    0deg,
    rgba(22, 53, 63, 1) 0%,
    rgba(33, 80, 96, 1) 32%,
    rgba(166, 209, 224, 1) 100%
  ); */
  /* background-image: radial-gradient(
    circle at 50% 95%,
    #ffa400ff 0%,
    #fd6e00ff 33.3333%,
    black 60%,
    black 100%
  );
  background-size: 100% 300%;
  background-position: 50% 100%; */
}
</style>
